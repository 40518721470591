import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { Image } from 'app/components/Common/Image'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  image?: { [key: string]: any }
  label?: string
  title?: string
}

export const Visual = memo(function Visual({
  cta,
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container>
      <FadeInOutAnimation>
        {image ? (
          <LazyLoad>
            <Background className="visual-image" {...image} />
          </LazyLoad>
        ) : null}
        <Wrap>
          {label ? <Label>{label}</Label> : null}
          {title ? <Title>{title}</Title> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {cta ? cta.map((item, index) => <CTA key={index} {...item} />) : null}
        </Wrap>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.section`
  overflow: hidden;
  margin: 9rem auto;
  padding-top: 23%;
  position: relative;
  &:hover {
    &:before {
      opacity: 0.6;
    }
    .visual-image {
      transform: scale(1);
    }
  }
  &:before {
    content: '';
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    width: 100%;
    height: 100%;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-out;
  }
  .react-reveal {
    &:last-of-type {
      position: relative;
      z-index: 2;
    }
  }

  @media (max-width: 1199px) {
    margin: 7.5rem auto;
  }

  @media (max-width: 1023px) {
    padding-top: 18%;
  }

  @media (max-width: 767px) {
    margin: 5.25rem auto;
  }
`

const Background = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.3s ease-out;
`

const Wrap = styled.div`
  max-width: 50rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
  padding: 0 1.5rem;
  position: relative;
  text-align: center;
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 0.9375rem;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  opacity: 0.7;
  margin-bottom: 3.75rem;
`

const CTA = styled(Button)`
  z-index: 2;
`
