import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  description?: string
  image?: string
  title?: string
}

export const Service = memo(function Service({
  description,
  image,
  title,
}: Props) {
  return (
    <Container>
      <FadeInOutAnimation>
        <Item>
          {image ? (
            <LazyLoad>
              <BackgroundImage style={{ backgroundImage: `url(${image})` }} />
              <ImageWrap>
                <Image style={{ backgroundImage: `url(${image})` }} />
              </ImageWrap>
            </LazyLoad>
          ) : null}
          {title ? (
            <TextWrap dial={5}>
              <Title>{title}</Title>
            </TextWrap>
          ) : null}
        </Item>
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.div`
  width: calc(33.333% - 3.9375rem);
  margin-top: 5.25rem;

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 3rem;
  }
`

const ImageWrap = styled.span`
  width: calc(100% - 2.25rem);
  height: calc(100% - 4.5rem);
  overflow: hidden;
  position: absolute;
  top: 2.25rem;
  left: 2.25rem;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-out;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 1.5rem);
    height: calc(100% - 3rem);
    top: 1.5rem;
    left: 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.3s ease-out;
`

const BackgroundImage = styled.span`
  width: 56%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-out;

  @media (max-width: 767px) {
    display: none;
  }
`

const Item = styled.span`
  display: block;
  height: 100%;
  padding-bottom: 100%;
  position: relative;

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`

const TextWrap = styled(FlexBox)`
  width: calc(100% - 2.25rem);
  height: calc(100% - 4.5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 2.25rem 1.5rem;
  position: absolute;
  top: 2.25rem;
  left: 2.25rem;
  z-index: 2;

  @media (max-width: 1023px) {
    width: calc(100% - 1.5rem);
    height: calc(100% - 3rem);
    padding: 1.5rem;
    top: 1.5rem;
    left: 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 1.875rem;
  p {
    margin: 0;
  }

  @media (max-width: 767px) {
    margin-top: 0;
    padding: 2.25rem 1.5rem 0;
  }
`
