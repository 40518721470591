import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { SEO } from 'app/components/SEO'
import { ServicesStripe } from 'app/components/ServicesStripe'
import { Visual } from 'app/components/Visual'
import { VisualServices } from 'app/components/VisualServices'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id?: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function ServicesPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.servicesStripeProps ? (
        <ServicesStripe variant="simple" {...context.servicesStripeProps} />
      ) : null}
      {context.visualServicesProps ? (
        <VisualServices {...context.visualServicesProps} />
      ) : null}
      {context.visualProps ? <Visual {...context.visualProps} /> : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix || 'it'}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
