import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServicesProps, Service } from './Service'

export interface Props {
  services?: ServicesProps[]
}

export const VisualServices = memo(function VisualServices({
  services,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container row space="between" stretch wrap tag="section">
      {services.map((item, index) => {
        return <Service key={index} {...item} />
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  max-width: 73.5rem;
  margin: -5.25rem auto 9rem;
  padding: 0 1.5rem;
  &:after {
    content: '';
    flex: 0 0 calc(33.333% - 3.9375rem);
  }

  @media (max-width: 1199px) {
    margin-bottom: 7.5rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 5.25rem;
    padding: 0;
  }
`
